import React from 'react'
import styled, {css} from 'styled-components'
import {globalWidth} from './base.js'

const Container = styled.div`
  ${globalWidth}
  margin: 0 auto;
`

export default ({ children }) => (
  <Container>{children}</Container>
)
