import React from 'react'
import './base.css'
import Container from './container'
import Navigation from './navigation'

export default function Template({children}){
  return(
    <Container>
      <Navigation />
      {children}
    </Container>
  )
}
