import React from 'react'
import { Link } from 'gatsby'
// import styles from './navigation.module.css'
import styled from 'styled-components'
import {globalWidth} from './base.js'

const Nav = styled.nav`
  width: 100%;
  height: 50px;
  position:fixed;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 9999;
  background: #ffffff;
`

const NavList = styled.ul`
  ${globalWidth}
  margin: 0 auto;
  display: flex;
  height: 100%;
  align-items: center;
  list-style: none;
  font-size: .9em;
  padding: 0 2vmin;
  justify-content: space-between;

`
const NavItem = styled.li`
  display: inline-flex;
  align-items: center;
  margin: 0;
  a {
    color: currentColor;
    text-decoration: none;
  }
`

const StyledLink = styled(Link)`
  padding: 0 2vmin;
`

export default () => (
  <Nav role="navigation">
    <NavList>
      <NavItem>
        <Link to="/"><b>Hyuntak Cha</b></Link>
      </NavItem>
      <NavItem>
        <StyledLink to="/about/">About</StyledLink>
        {/* <StyledLink to="/blog/">Blog</StyledLink> */}
      </NavItem>
    </NavList>
  </Nav>
)
